.comm-log-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0rem;

  h1 {
    margin-bottom: 0;
  }

  div {
    margin-top: 16px;
  }

  @media (min-width: 84em) {
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    h1 {
      margin: .67em 0;
    }

    div {
      margin-top: 0;
    }
  }
}

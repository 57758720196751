@use '../../mixins.scss';

@media (min-width: 30em){
  .ttahub-resource-repeater--add-new .usa-button {
    margin-top: 0;
  }
}

.ttahub-resource__error input[type="url"] {
    @include mixins.error;
}

button.ttahub-resource-repeater--remove-resource {
  margin-top: 0;
}
@use '../../../colors.scss' as *;

.ttahub-activity-report-view-table-container {
  border: 1px solid $base-light;
  width: calc(100% - 4rem);
}

.ttahub-activity-report-view-table {
  font-size: 0.9rem;
  width: 100%;
}

.ttahub-activity-report-view-table caption {
  font-size: 1.25rem;
}

.ttahub-activity-report-view-table th,
.ttahub-activity-report-view-table td {
  border: 0;
  padding: 1rem;
}

.ttahub-activity-report-view-table td h2 {
  font-size: 14px;
}

.ttahub-activity-report-view-table th {
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .ttahub-activity-report-view-table th {
    width: 200px;
  }
}

@media print {
  .ttahub-activity-report-view-table-container {
    border: none;
    width: 100%;
  }

  .ttahub-activity-report-view-table th {
    padding: 0.5em 1em;
    vertical-align: top;
    width: 200px;
  }

  .ttahub-activity-report-view-table td {
    padding: 0.5em 1em;
    vertical-align: top;
  }

  .ttahub-activity-report-view-table td ul {
    margin: 0;
  }
}

.statistics-content p,
.statistics-content h2 {
    grid-column: span 3;
    text-align: center;
}

.statistics-content h2 span {
    display: inline-block;
    margin: 0 0.25rem;
}

.statistics-content--loaded h2 span {
    animation: rotatin cubic-bezier(0.39, 0.575, 0.565, 1) 1.33s forwards;
}

.statistics-content.statistics-content .smart-hub--dashboard-overview-field{
    width: 100%;
}

@media screen and (min-width: 64em) {
    .statistics-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.5rem;
        padding-bottom: 4rem;
    }

    .statistics-content-heart-logo {
        grid-row: span 3;
        padding: 0.5rem
    }
}


@media(prefers-reduced-motion: reduce) {
   .statistics-content h1 span {
        /**
        this needs to be !important, because we are considering the user preference for no animation
        to be more important than any of our little tricks and we don't want stuff to leak through
        now or in the future
        **/
        animation: none !important;
    }
}

@keyframes rotatin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


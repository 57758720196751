@media( min-width: 1024px ){
    .smart-hub--dashboard-overview-widget-field {
        margin: 0;
    }
}

.smart-hub--dashboard-overview-widget-field-icon {
    border-radius: 50%;
  }

  .smart-hub--dashboard-overview-widget-field-icon__background-sm {
      height: 3em;
      width: 3em;
}

.smart-hub--dashboard-overview-widget-field .smart-hub--ellipsis {
    max-width: 200px;
}

.smart-hub--dashboard-overview-widget-field .smart-hub--no-results-found {
    margin: auto;
}
@use '../colors.scss' as *;

%badge {
    background-color: $success-darkest;
    border-radius: 12px;
    padding: 4px 12px;
  }

  .ttahub-badge--success {
    @extend %badge;
  }

  .ttahub-badge--warning {
    background-color: $warning;
    @extend %badge;
  }

  .ttahub-badge--error {
    background-color: $error;
    @extend %badge;
  }

@use '../../../colors.scss' as *;

.smart-hub--navigator-link-active {
  color: $ttahub-medium-blue;
  font-weight: bold;
  border-left: 4px solid $ttahub-medium-blue;
  text-decoration-line: none;
}

.smart-hub--navigator-link {
  display: flex;
  margin: auto;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: $ttahub-blue;
  line-height: 24px;
}

.smart-hub--navigator-link:hover {
  background-color: $base-lightest;
  color: $ttahub-medium-blue;
}

.smart-hub--navigator-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.smart-hub--navigator-item {
  width: 100%;
  height: 40px;
  color: $ttahub-medium-blue;
}

.smart-hub--tag-not-started {
  background-color: $base-lightest;
}

.smart-hub--tag-in-progress {
  background-color: $info-lighter;
}

.smart-hub--tag-complete {
  background-color: $success-lighter;
}

.smart-hub--tag-submitted {
  background-color: $base-lighter;
}

.smart-hub--tag-needs-action {
  background-color: $error-lighter;
}

.smart-hub--tag {
  width: 94px;
  text-align: center;
  font-weight: normal;
  display: inline-block;
  text-transform: none;
  font-size: 14px;
  color: $text-ink;
}

.smart-hub--navigator-skip-link {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.06rem;
  line-height: 1.5;
  color: $text-link;
  text-decoration: underline;
  background: transparent;
  left: 0;
  top: -25rem;
  padding: 0.5rem 1rem;
  position: absolute;
  transition: 0.2s ease-in-out;
  z-index: 100;
}

.smart-hub--navigator-skip-link:focus {
  background: white;
  left: 0;
  position: absolute;
  top: -2.5rem;
  transition: 0.2s ease-in-out;
}

.smart-hub--navigator-item:first-child .smart-hub--navigator-link-active {
  border-top-right-radius: 4px;
}

.smart-hub--navigator-item:last-child .smart-hub--navigator-link-active {
  border-bottom-right-radius: 4px;
}

.alert-fade {
  animation: fadein 1s;
  animation-fill-mode: forwards;
  animation-delay: 0ms;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@use '../colors.scss' as *;

.ttahub-tabs-nav_nav {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
}

@media all and (min-width: 30em){
    .ttahub-tabs-nav_nav {
        margin-left: -1.5rem;
    }
}

@media all and (min-width: 40em){
    .ttahub-tabs-nav_nav {
        margin-left: -2.5rem;
    }
}

.ttahub-tabs-nav_link, .ttahub-tabs-nav_link:visited {
    color: $text-ink;
    text-decoration: none;
}

.ttahub-tabs-nav_tab {
    min-width: 6rem;
    text-align:center;
}

.ttahub-tabs-nav_link--active{
    border-bottom: 4px solid $ttahub-blue;
    font-weight: bold;
}

a.ttahub-tabs-nav_back-to-search, a.ttahub-tabs-nav_back-to-search:visited {
    color: $ttahub-blue;
}
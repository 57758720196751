@use '../../colors' as *;

.goals-table .usa-table-container,
.goals-table .usa-checkbox__label {
  margin-top: 0px;
}

.goals-table .usa-table {
  box-sizing: border-box;
  line-height: 2.5;
  margin-left: 1px;
  max-width: calc(100% - 1px);
}

.goals-table h2 {
  margin-left: 0px;
}

.goals-table .usa-table thead th a {
  cursor: pointer;
}

.goals-table .usa-table thead th {
  background-color: #fff;
  padding-right: 0px;
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 8px;
  background-color: #fff;
  border: 0;
  border-top: 1.5px solid  $base-lighter;
}

.goals-table .usa-table-container {
  padding-bottom: 16px;
  overflow-x: auto;
}

.goals-table .usa-table-container > table {
  border-collapse: separate;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.goals-table .usa-table .usa-checkbox__label {
  padding-left: 0;
}
.goals-table .usa-table .usa-checkbox__label::before {
  position: relative;
}

.goals-table .smart-hub--total-count {
  padding-right: 0px;
}

.goals-table .smart-hub--link-next {
  margin-right: 0px;
}

.goals-table .disabled {
  display: none;
}
@use '../../../colors' as *;

$min-width-title-column: 200px;
$min-width-id-column: 107px;
$min-width-organizer-column: 150px;
$min-width-reason-column: 160px;
$min-width-date-column: 115px;
$min-width-menu-column: 20px;

.ttahub-event-card {
  container: ttahub-event-card / inline-size;
}

// reset font sizes
.ttahub-event-card,
.ttahub-event-card .smart-hub-tooltip .smart-hub--ellipsis {
  font-size: 1.06rem;
}

.ttahub-event-card .smart-hub--menu-button .fa-ellipsis {
  font-size: 1.5rem;
}

.ttahub-event-card__event-column:not(:nth-last-child(2)) {
  margin-bottom: 16px;
}

// Hide tool tip button.
.ttahub-event-card__event-reason-tool-tip {
  display: none;
}

// Show tool tip csv.
.ttahub-event-card__event-reason-csv {
  display: block;
}

@container ttahub-event-card (min-width: 710px) {
  .ttahub-event-card__row {
    display: flex;
    flex-wrap: wrap;
  }

  .ttahub-event-card__event-column:not(:nth-child(n+1):nth-child(-n+3)) {
    margin-bottom: 0px;
  }


  .ttahub-event-card__event-column:not(:last-child) {
    margin-bottom: 16px;
  }

  .ttahub-event-card__event-column__title,
  .ttahub-event-card__event-column__reason {
    width: 40%;
  }

  .ttahub-event-card__event-column__id,
  .ttahub-event-card__event-column__organizer,
  .ttahub-event-card__event-column__date {
    width: 29%;
  }

  // Event Context Menu.
  .ttahub-event-card__event-column__menu {
    flex-basis: clamp($min-width-menu-column, 2%, 2%);
  }

}

@container ttahub-event-card (min-width: 980px) {
  .ttahub-event-card__event-column:not(:last-child) {
    margin-bottom: 0px;
  }

  // Title.
  .ttahub-event-card__event-column__title {
    flex-basis: clamp($min-width-title-column, 24%, 100%);
  }

  // Event ID.
  .ttahub-event-card__event-column__id {
    flex-basis: clamp($min-width-id-column, 12%, 100%);
  }

  // Event Organizer.
  .ttahub-event-card__event-column__organizer {
    flex-basis: clamp($min-width-organizer-column, 15%, 100%);
  }

  // Event Reason.
  .ttahub-event-card__event-column__reason {
    flex-basis: clamp($min-width-reason-column, 20%, 100%);
  }

  // Reason overflow.
  .ttahub-event-card__event-column__reason p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
  }

  // Event Date.
  .ttahub-event-card__event-column__date {
    flex-basis: clamp($min-width-date-column, 12.5%, 100%);
  }

  // Event Context Menu.
  .ttahub-event-card__event-column__menu {
    flex-basis: clamp($min-width-menu-column, 2%, 100%);
  }

  // Show tool tip button.
  .ttahub-event-card__event-reason-tool-tip {
    display: block;
  }

  // Hide CSV tool tip list.
  .ttahub-event-card__event-reason-csv {
    display: none;
  }
}
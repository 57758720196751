@media( min-width: 64em ){
    .smart-hub--dashboard-overview  {      
        gap: 1em;
    }
    .smart-hub--dashboard-overview-field {
        margin: 0;
    }
}

.smart-hub--dashboard-overview-field-icon-background {
    border-radius: 50%;
    height: 3em;
    width: 3em;
}

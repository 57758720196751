@use '../../../colors.scss' as *;

.ttahub-recipient-results .usa-table .usa-button--unstyled {
    color: $text-ink;
    font-size: 1em;
    font-weight: bold;
    text-decoration: none;
}

.ttahub-recipient-results .usa-table td {
    white-space: unset;
}

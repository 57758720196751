
// STOP! Don't edit this file.
// Instead, edit colors.js and run 'yarn makecolors'
// or if you are using docker, yarn docker:makecolors
// to generate this file (the colors variables are used in two ways, inlined in javascript and directly in css
// to keep things consistent, we update only in one place - the colors.js file, and use
// yarn makecolors to keep the files identical)

// figma reference here:
// https://www.figma.com/file/5Fr0NKQf9MQ5WGd8BWxA6i/TTA_SmartHub-Library-09132021?node-id=0%3A14

$ttahub-blue: #264a64;
$ttahub-medium-blue: #336A90;
$ttahub-blue-light: #e2eff7;
$ttahub-blue-lighter: #eceef1;
$ttahub-medium-deep-teal: #407972;
$ttahub-deep-teal-light: #EEF2EB;
$ttahub-magenta: #A12854;
$ttahub-magenta-light: #ffe8f0;
$ttahub-orange: #e29f4d;
$ttahub-orange-light: #fff1e0;
$base-darkest: #1b1b1b;
$base-dark: #565c65;
$base-medium: #71767A;
$base-light: #a9aeb1;
$base-lighter: #dfe1e2;
$base-lightest: #f0f0f0;
$gray-two: #f9f9f9;
$info: #00bde3;
$info-lighter: #e7f6f8;
$success: #00a91c;
$success-lighter: #ecf3ec;
$success-darker: #00a91c;
$success-darkest: #008817;
$error: #d54309;
$error-lighter: #f4e3db;
$warning: #ffbe2e;
$warning-lighter: #faf3d1;
$error-dark: #b50909;
$blue-vivid-focus: #2491FF;
$text-ink: #1b1b1b;
$text-link: #46789B;
$text-visited: #8C39DB;
$response-code: #71767A;
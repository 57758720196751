@use '../../mixins.scss';
@use '../../colors.scss' as *;

.ttahub-filter-menu-item {
    gap: 1em;
}

.ttahub-filter-menu-item [name="topic"] {
    width: 240px;
}

.ttahub-filter-menu-item [name="condition"] {
    width: 150px;
}

.ttahub-filter-menu-item.ttahub-filter-menu-item--error {
    padding: 1em 1em 0em;
    margin: 1em 0 1em calc( -1em - 4px );
}

.ttahub-filter-menu-error {
    position: absolute;
    top: 0;
}

.ttahub-filter-menu-item--error.ttahub-filter-menu-item--error--filter [name="topic"],
.ttahub-filter-menu-item--error.ttahub-filter-menu-item--error--condition [name="condition"],
.ttahub-filter-menu-item--error.ttahub-filter-menu-item--error--value .usa-input,
.ttahub-filter-menu-item--error.ttahub-filter-menu-item--error--value .usa-select:nth-last-child(2),
.ttahub-filter-menu-item--error.ttahub-filter-menu-item--error--value .ttahub-filter-date-range-single-date {
    @include mixins.error;
}

.ttahub-filter-menu-item--error.ttahub-filter-menu-item--error--value .smart-hub--checkbox-select .usa-select {
    @include mixins.error;
}

.ttahub-filter-menu-item.ttahub-filter-menu-item--error :blank {
    @include mixins.error;
}

.ttahub-filter-menu-item > :nth-last-child(2),
.ttahub-dummy-select {
    max-width: none;
    width: 300px;
}

.ttahub-filter-menu-item .usa-select {
    margin-top: 0.5em;
}


@media(max-width: 768px) {
    .ttahub-filter-menu-item {
        border: 1px solid $base-lightest;
        padding: 0.5em;
    }
}


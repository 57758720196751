.grid-container {
  max-width: 100%;
}

@media( min-width: 640px){
  .smart-hub--dashboard-overview  {
      column-gap: 1rem;
      row-gap:.5rem;
  }
}

@media( max-width: 768px ) {
  .ttahub-dashboard--filters {
    align-items: flex-start;
    flex-direction: column;
  }
}

.ttahub--dashboard-widget-heading {
  font-size: 1.5em;
}

@use '../../mixins.scss';

/** text inputs **/
.ttahub-create-goals-form .usa-error-message + :invalid, 
/** the <Select /> component **/
.ttahub-create-goals-form .usa-error-message + .usa-select, 
/** URL fields are wrapped in a div **/
.ttahub-create-goals-form .usa-error-message + .ttahub-resource-repeater input[type="url"] {
    @include mixins.error;
}

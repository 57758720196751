@use '../../colors' as *;

$min-width-text-column: 428px;
$min-width-source-column: 220px;
$min-width-date-column: 140px;
$max-width-date-column: 50%;

// reset font sizes
.ttahub-goal-card {
  font-size: 1.06rem;
}

.ttahub-goal-cards .ttahub-goal-card:last-of-type {
  margin-bottom: 0;
}

.ttahub-goal-card .smart-hub--menu-button .fa-ellipsis {
  font-size: 1.5rem;
}

.ttahub-goal-card__goal-column__goal-text {
  flex-basis: clamp($min-width-text-column, 100%, 100%);
}

.ttahub-goal-card__goal-column:not(:last-child) {
  margin-bottom: 16px;
}

@media(min-width: 627px) {
  .ttahub-goal-card__goal-column:not(:nth-child(n+3):nth-child(-n+5)) {
    margin-bottom: 16px;
  }
}

.ttahub-goal-card__goal-column__goal-source {
  flex-basis: clamp($min-width-source-column, 100%, 100%);

}

.ttahub-goal-card__goal-column__created-on,
.ttahub-goal-card__goal-column__last-tta,
.ttahub-goal-card__goal-column__entered-by {
  flex-basis: clamp(33%, 33%, 50%);
}

@media(min-width: 1215px) {

  .ttahub-goal-card__goal-column:not(:nth-child(n+3):nth-child(-n+5)) {
    margin-bottom: 16px;
  }

  .ttahub-goal-card__goal-column__goal-text {
    flex-basis: clamp($min-width-text-column, 60%, 100%);
  }

  .ttahub-goal-card__goal-column__goal-source {
    flex-basis: clamp(200px, 40%, 100%);
  }
}

@media(min-width: 1475px) {

  .ttahub-goal-card__goal-column__goal-source p {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 220px;
  }


  .ttahub-goal-card__goal-column__goal-text {
    flex-basis: clamp($min-width-source-column, 40%, 100%);
  }

  .ttahub-goal-card__goal-column__goal-source {
    flex-basis: clamp($min-width-source-column, 20%, $min-width-source-column);
  }

  .ttahub-goal-card__goal-column__created-on,
  .ttahub-goal-card__goal-column__last-tta,
  .ttahub-goal-card__goal-column__entered-by {
    flex-basis: clamp($min-width-date-column, 10%, $max-width-date-column);
  }
}

.ttahub-goal-card__entered-by-tooltip > .usa-tooltip__body,
.ttahub-goal-card__entered-by-tooltip.smart-hub-tooltip:hover .usa-tooltip__body,
.ttahub-goal-card__entered-by-tooltip .smart-hub-tooltip.show-tooltip .usa-tooltip__body {
  white-space: nowrap;
}

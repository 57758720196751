// hide the "blog post by" text
.ttahub-feed-article-content p:has(a:not([href])){
    display: none;
}

// remove inline styles from feed
// - since they are inline, we have to use !important
.ttahub-feed-article-content .feed > div {
    border: none !important;
    padding: 0 !important;
}

.ttahub-feed-article-content .feed div:last-child{
    display: none;
}



.ttahub-feed-article-content .feed a[href^="http"]:after {
    content: "\f35d";
    display: inline-block;
    font: normal normal normal 16px/1 FontAwesome;
    margin: 0 8px;
}
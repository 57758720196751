@use '../../../../colors.scss' as *;

.ttahub-class-feed-article .ttahub-feed-article {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.ttahub-class-feed-article .ttahub-feed-article p:has(strong){
  text-align: left;
}
.ttahub-class-feed-article .ttahub-feed-article th .usa-prose {
  margin: 0;
}

.smart-hub--overview-data {
  flex-direction: column;
}

@media all and (min-width: 64em){
  .smart-hub--overview-data {
    flex-direction: row;
  }
}

.smart-hub--overview-nowrap {
  white-space: nowrap;
}

.smart-hub--overview-font-size {
  font-size: 19px;
}
@use '../../colors.scss' as *;

.ttahub-activity-report-view {
  h1 {
    font-size: 36px;    
    margin: 0 0 0.5rem 0;
    white-space: initial;
  }

  .public-DraftStyleDefault-block {
    margin: 0;
  }
}

@media( min-width: 1200px ) {
  .ttahub-activity-report-view {
    max-width: 900px;
  }
}

@media print {
  .ttahub-activity-report-view {
    margin-top: 0;
  }
  .ttahub-activity-report-view > div.padding-x-5.padding-y-5 {
    padding-top: 0;
  }

  .smart-hub-offset-nav {
    margin-top: 0 !important;
    margin-right: 0;
  }

  .smart-hub-offset-nav, .ttahub-activity-report-view {
    max-width: none;
  }
  
  .ttahub-activity-report-view .activity-summary-table caption {
    border-top: 1px solid $base-light;
    border-left: 1px solid $base-light;
    border-right: 1px solid $base-light;
    margin-bottom: 0;
  }
    
  .ttahub-activity-report-view .activity-summary-table td, 
  .ttahub-activity-report-view .activity-summary-table th {
    border: 1px solid $base-light;
   }

  .ttahub-activity-report-view h1 {
    margin: 1em 0;
  }

  .ttahub-activity-report-view hr {
    display: none;
  }
 
  .smart-hub-offset-nav > .grid-col-12 {
    margin: 0;
  }
    
  /* how clumsy it is to set padding-top 0 to a class named padding 5? */
  .ttahub-activity-report-view > .padding-5 {
    padding: 0;
  }
}

@use '../colors.scss' as *;

.smart-hub-drawer {
  max-width: 33%;
}

.smart-hub-drawer-header {
  z-index: 2;
}

.usa-button.smart-hub-button--no-margin {
  margin: 0;
}

// on small devices, set max-width of smart-hub-drawer to 100%;
@media (max-width: 768px) {
  .smart-hub-drawer {
    left: 0;
    width: 100%;
    max-width: 100%;
  }
}

.slide-in-right {
  animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(5%);
    opacity: 0.1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@use '../../colors.scss' as *;

.smart-hub--report-checkbox {
  margin-top: 1.25rem;
}

.smart-hub--report-legend .usa-legend {
  font-family: 'Source Sans Pro Web';
  font-size: 17px;
  color: $text-ink;
  line-height: 22px;
  height: 16px;
  font-weight: 700;
}

.usa-label {
  font-size: 1rem;
}

.smart-hub--form-large {
  max-width: 33rem;
}

.usa-label {
  max-width: 500px;
}

.usa-accordion__button {
  background-color: white;
  height: 64px;
  padding-right: 40px;
  padding-left: 40px;
  background-position-x: calc(100% - 40px), calc(100% - 40px);
}

.usa-accordion__button[aria-expanded=false] {
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.usa-accordion__button[aria-expanded=true] {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  box-shadow: 0 -6px 8px 0px rgba(0, 0, 0, 0.1);
}

.usa-accordion__content {
  padding-top: 0px;
  padding-right: 40px;
  padding-left: 40px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.icon {
  display:inline-block;
  font-size: 40px;
  line-height: 50px;
  background-color:black;
  color:white;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: bottom;
}

.smart-hub--tag-approved {
  background-color: $success-lighter;
  color: $success;
}

.smart-hub--text-area__resize-vertical {
  resize: vertical;
}

.smart-hub--text-area__resize-vertical .rdw-editor-main {
  resize: vertical;
}

.usa-form-group--error .ttahub-multi-select [class$="-control"],
.usa-form-group--error .usa-input:not([type="url"]),
.usa-form-group--error .ttahub-resource-repeater :invalid {
  border: 2px solid #b50909;
}

.smart-hub--form__activity-report-form legend:first-of-type,
.smart-hub--report-legend__no-legend-margin-top legend{
  margin-top: 0;
}
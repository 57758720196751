.usa-button.ttahub-objective-select-remove-objective {
    margin-top: 0;
}

.usa-error-message + .usa-select{
    border: 2px solid #b50909;
}

#remove-objective-modal .usa-modal {
  max-width: 35rem;
}

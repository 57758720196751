@use '../../mixins.scss';

.ttahub-custom-date-range-picker-fields:not([hidden]) {    
    z-index: 2;
}

.ttahub-custom-date-range-picker--error-start-date #start-date,
.ttahub-custom-date-range-picker--error-end-date  #end-date{
    @include mixins.error;
}

.ttahub-custom-date-range-picker-fields {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
}